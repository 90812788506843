<template>
  <div id="app">
    <router-view />
  </div>
</template>



<script>
export default {
  created() {},
};
</script>

<style>
html {
  height: 100%;
}
body {
  height: 100%;
}
#app {
  height: 100%;
}
</style>

