import Vue from 'vue'
import VueRouter from 'vue-router'
const index = () => import(/* webpackChunkName: "login" */ '../views/index.vue')
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
} // 重复点击路由报错的问题
Vue.use(VueRouter)

const routes = [
  // { path: "/", redirect: '/' },
  {
    path: '/',
    name: '/',
    component: index, // 首页
  },
  {
    path: '/index',
    name: 'index',
    component: index, // 首页
  },
  {
    path: '/video',
    name: 'video', // 企业简介
    component: () => import(/* webpackChunkName: "brief" */ '../views/video.vue'),
  },
  {
    path: '/culture',
    name: 'Culture', // 企业文化
    component: () => import(/* webpackChunkName: "Culture" */ '../views/Culture.vue'),
  },
  {
    path: '/brief',
    name: 'brief', // 企业简介
    component: () => import(/* webpackChunkName: "brief" */ '../views/brief.vue'),
    meta: {
      content: {
        description:
          '安谋科技在自研业务方面始终积极布局、大力投入，专注于AI、CPU、信息安全及多媒体处理等核心研发领域，创新性地推出了自研业务产品线，包括“周易”NPU、“星辰”CPU、“山海”SPU以及“玲珑”多媒体系列等，并全部实现了客户相关产品的流片和量产。',
      },
    },
  },
  {
    path: '/chairman',
    name: 'chairman', // 董事长简介
    component: () => import(/* webpackChunkName: "brief" */ '../views/chairman.vue'),
  },
  {
    path: '/search',
    name: 'search', // 搜索
    component: () => import(/* webpackChunkName: "brief" */ '../views/search.vue'),
  },
  // {
  //   path: "/development",
  //   name: "development", // 发展历史
  //   component: () =>
  //     import(/* webpackChunkName: "development" */ "../views/development.vue"),
  // },
  {
    path: '/armip',
    name: 'armip', // Arm-ip 产品列表
    component: () => import(/* webpackChunkName: "armip" */ '../views/Armproduct/Armip.vue'),
  },
  {
    path: '/mouList',
    name: 'mouList', // 山海列表
    component: () => import(/* webpackChunkName: "mountain" */ '../views/Corepower/MouList.vue'),
  },
  {
    path: '/mountain',
    name: 'mountain', // 核心动力产品线
    component: () => import(/* webpackChunkName: "mountain" */ '../views/Corepower/Mountain.vue'),
  },
  {
    path: '/download/:level?/:level2?/:level3?',
    name: 'download', // 下载
    component: () => import(/* webpackChunkName: "mountain" */ '../views/Corepower/download.vue'),
  },
  {
    path: '/infolist',
    name: 'infolist', // 企业资讯
    component: () => import(/* webpackChunkName: "mountain" */ '../views/news/information/infoList.vue'),
  },
  {
    path: '/infodetails',
    name: 'infodetails', // 资讯详情
    component: () => import(/* webpackChunkName: "mountain" */ '../views/news/information/infoDetails.vue'),
  },
  {
    path: '/activitylist',
    name: 'activitylist', // 公司活动
    component: () => import(/* webpackChunkName: "mountain" */ '../views/news/information/activitylist.vue'),
  },
  {
    path: '/contactus',
    name: 'contactus', // 联系我们
    component: () => import(/* webpackChunkName: "mountain" */ '../views/ContactUs/Contactus.vue'),
  },
  {
    path: '/agent',
    name: 'agent', // 代理商信息
    component: () => import(/* webpackChunkName: "ContactUs" */ '../views/ContactUs/agent.vue'),
  },
  {
    path: '/usestandard',
    name: 'usestandard', // Arm商标使用规范
    component: () => import(/* webpackChunkName: "mountain" */ '../views/UseStandard.vue'),
  },
  {
    path: '/dome',
    name: 'dome',
    component: () => import(/* webpackChunkName: "dome" */ '../views/dome.vue'),
  },
  {
    path: '/ecology',
    name: 'ecology',
    component: () => import(/* webpackChunkName: "dome" */ '../views/ecology/ecology.vue'),
  },
  {
    path: '/platform',
    name: 'platform',
    component: () => import(/* webpackChunkName: "dome" */ '../views/ecology/platform.vue'),
  },
  {
    path: '/cooperate',
    name: 'cooperate',
    component: () => import(/* webpackChunkName: "dome" */ '../views/ecology/cooperate.vue'),
  },
  {
    path: '/cooperateInfo',
    name: 'cooperateInfo',
    component: () => import(/* webpackChunkName: "dome" */ '../views/ecology/cooperateInfo.vue'),
  },
  {
    path: '/applyjoin',
    name: 'applyjoin',
    component: () => import(/* webpackChunkName: "dome" */ '../views/ecology/applyJoin.vue'),
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import(/* webpackChunkName: "dome" */ '../views/ecology/agreement.vue'),
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "dome" */ '../views/ecology/Privacy.vue'),
  },
  {
    path: '/unsubscribe',
    name: 'unsubscribe',
    component: () => import(/* webpackChunkName: "dome" */ '../views/ecology/Unsubscribe.vue'),
  },
  {
    path: '/development',
    name: 'development',
    component: () => import(/* webpackChunkName: "dome" */ '../views/fzls.vue'),
  },
  {
    path: '/quality',
    name: 'quality',
    component: () => import(/* webpackChunkName: "dome" */ '../views/quality.vue'),
  },
  {
    path: '/ResourceLibrary',
    name: 'ResourceLibrary',
    component: () => import(/* webpackChunkName: "dome" */ '../views/ResourceLibrary.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    return { x: 0, y: 0 }
  },
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */

  if (to.meta.content) {
    let head = document.getElementsByTagName('head')
    let meta = document.createElement('meta')
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content
    head[0].appendChild(meta)
  }
  next()
})

export default router
